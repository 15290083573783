import {
  useRef,
  useEffect,
  forwardRef,
  ForwardRefRenderFunction,
  useImperativeHandle,
  useState
} from 'react'

// import StreamingAvatar, {

//   StartAvatarRequest,
//   AvatarQuality,
//   VoiceEmotion,
//   StartAvatarResponse,

//   // Configuration,
//   // ConfigurationParameters,
//   // NewSessionData,
//   // NewSessionRequest,
//   // StreamingAvatarApi
// } from '@heygen/streaming-avatar'

import * as LivekitClient from 'livekit-client'

import RVideo from './recorder/Rvideo'
import get from 'lodash/get'
import has from 'lodash/has'
import set from 'lodash/set'
// import BackgroundRemover from './BackgroundRemover'
import jsLogger from 'js-logger'
import fetchWithRetries from 'shared/utils/fetchWithRetries'

export interface IHeyGenConnect {
  say: (text: string) => Promise<string | null>
  stop: () => Promise<void>
  cancel: () => void
  play: () => void
}

type Props = {
  onAvatarPlayingFinished: () => void
  onAvatarStartTalking: (latency: number) => void
  thereWasAnError?: string
  setThereWasAnError?: (v: string) => void
  onSessionStarted: () => void
  isRecording: boolean
  permissionsGranted: boolean
  handleChunk?: (videoBlob: Blob, mimeType: string, role: 'avatar') => void
  setDuration: (v: number) => void
  handleVideoClick: () => void
  heygenKey: string
  avatarId?: string
  avatarQuality?: string
  voiceId?: string
  // initialPhrase?: string
  lang?: string
  avatarImageUrl?: string
}

// const defaultAvatarId = 'a11567e7a1cf4be2bc7a92a0ce0355e2'
const serverUrl = 'https://api.heygen.com'

const HeyGenConnect: ForwardRefRenderFunction<IHeyGenConnect, Props> = (
  {
    onAvatarPlayingFinished,
    thereWasAnError,
    onAvatarStartTalking,
    setThereWasAnError,
    onSessionStarted,
    isRecording,
    permissionsGranted,
    handleChunk,
    setDuration,
    handleVideoClick,
    heygenKey,
    // initialPhrase,
    avatarId = 'default',
    avatarQuality = 'high',
    voiceId,
    lang = 'en',
    avatarImageUrl
  },
  ref
) => {
  const videoRef = useRef<HTMLVideoElement>(null)
  const videoMutedRef = useRef<HTMLVideoElement>(null)
  const videoRecorderRef = useRef<RVideo>()
  const streamHasBeenSet = useRef<boolean>(false)
  const startTalkTimestamp = useRef<number>(0)
  const permissionsGrantedRef = useRef<boolean>(false)
  const canceledRef = useRef<boolean>(false)
  const sessionInfoRef = useRef(null)
  const websocketRef = useRef<WebSocket | null>(null)

  const roomRef = useRef<LivekitClient.Room | null>(null)

  const [stream, setStream] = useState<MediaStream>()

  useImperativeHandle(ref, () => ({
    say: startTalk,
    cancel: cancelTalking,
    stop: stopSession,
    play
  }))

  const play = () => {
    if (!streamHasBeenSet.current) {
      playVideo()
    }
  }

  useEffect(() => {
    if (permissionsGranted && stream) {
      permissionsGrantedRef.current = true
      // setTimeout(playVideo, 500)
      playVideo()
    }
  }, [permissionsGranted, stream])

  // Connect WebSocket
  const connectWebSocket = async (sessionId: string) => {
    jsLogger.log('Configuring request with session ID:', sessionId)
    const requestConf = {
      session_id: sessionId,
      session_token: String(heygenKey),
      silence_response: 'false',
      stt_language: lang
    }

    // if (initialPhrase) {
    //   jsLogger.log(
    //     'Initial phrase provided, adding to request configuration:',
    //     initialPhrase
    //   )
    //   set(requestConf, 'opening_text', initialPhrase)
    // }

    jsLogger.log('Final request configuration:', requestConf)
    const params = new URLSearchParams(requestConf)

    const wsUrl = `wss://${
      new URL(serverUrl).hostname
    }/v1/ws/streaming.chat?${params}`

    websocketRef.current = new WebSocket(wsUrl)

    // Handle WebSocket events
    websocketRef.current.addEventListener('message', event => {
      const eventData = JSON.parse(event.data)
      console.log('Raw WebSocket event:', eventData)
    })
  }

  // Create new session
  const createNewSession = async () => {
    try {
      jsLogger.log('Creating new session...')
      const response = await fetchWithRetries(`${serverUrl}/v1/streaming.new`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${heygenKey}`
        },
        body: JSON.stringify({
          quality: avatarQuality,
          avatar_name: avatarId,
          // voice: {
          //   voice_id: voiceId,
          //   rate: 1
          // },
          version: 'v2',
          video_encoding: 'H264'
        })
      })

      jsLogger.log('Session creation response received')
      const data = await response.json()
      const sessionInfo = data.data

      jsLogger.log('Session info obtained:', sessionInfo)
      sessionInfoRef.current = sessionInfo

      // Create LiveKit Room
      jsLogger.log('Creating LiveKit Room...')
      const room = new LivekitClient.Room({
        adaptiveStream: true,
        dynacast: true,
        videoCaptureDefaults: {
          resolution: LivekitClient.VideoPresets.h720.resolution
        }
      })

      jsLogger.log('LiveKit Room created')
      roomRef.current = room

      // Handle room events
      room.on(LivekitClient.RoomEvent.DataReceived, message => {
        const data = new TextDecoder().decode(message)
        const event = JSON.parse(data)
        jsLogger.log('Room message:', event)
        const eventType = get(event, 'type', '')
        switch (eventType) {
          case 'avatar_start_talking': {
            jsLogger.log('Event: avatar_start_talking')
            canceledRef.current = false
            const latency = Date.now() - startTalkTimestamp.current
            jsLogger.log('Latency calculated:', latency)
            onAvatarStartTalking && onAvatarStartTalking(latency)
            break
          }
          case 'avatar_stop_talking': {
            jsLogger.log('Event: avatar_stop_talking')
            onAvatarPlayingFinished()
            break
          }
          case 'avatar_talking_message': {
            // startTalkTimestamp.current = Date.now()
            break
          }
          default: {
            jsLogger.log('Unhandled event type:', eventType)
          }
        }
      })

      // Handle media streams
      const mediaStream = new MediaStream()

      room.on(LivekitClient.RoomEvent.TrackSubscribed, track => {
        if (track.kind === 'video' || track.kind === 'audio') {
          mediaStream.addTrack(track.mediaStreamTrack)
          setStream(mediaStream)
          if (
            mediaStream.getVideoTracks().length > 0 &&
            mediaStream.getAudioTracks().length > 0
          ) {
            jsLogger.log('Media stream ready')
            playVideo()
            // mediaElement.srcObject = mediaStream
          }
        }
      })

      // Handle media stream removal
      room.on(LivekitClient.RoomEvent.TrackUnsubscribed, track => {
        const mediaTrack = track.mediaStreamTrack
        if (mediaTrack) {
          mediaStream.removeTrack(mediaTrack)
        }
      })

      // Handle room connection state changes
      room.on(LivekitClient.RoomEvent.Disconnected, reason => {
        jsLogger.log(`Room disconnected: ${reason}`)
      })

      await room.prepareConnection(sessionInfo.url, sessionInfo.access_token)
      jsLogger.log('Connection prepared')

      // Connect WebSocket after room preparation
      await connectWebSocket(sessionInfo.session_id)

      jsLogger.log('Session created successfully')
    } catch (error) {
      jsLogger.error('Error creating new session:', error)
    }
  }

  // Start streaming session
  const startStreamingSession = async () => {
    try {
      const sessionInfo = sessionInfoRef.current
      if (sessionInfo) {
        jsLogger.log('SESSION INFO', sessionInfo)
        const sessionId = get(sessionInfo, 'session_id')
        const startResponse = await fetchWithRetries(
          `${serverUrl}/v1/streaming.start`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${heygenKey}`
            },
            body: JSON.stringify({
              session_id: sessionId
            })
          }
        )

        jsLogger.log('startResponse', startResponse)

        const room = roomRef.current
        if (room) {
          await room.connect(
            get(sessionInfo, 'url'),
            get(sessionInfo, 'access_token')
          )
          jsLogger.log('Connected to room')

          // document.querySelector('#startBtn').disabled = true
          jsLogger.log('Streaming started successfully')
        }
      }
    } catch (error) {
      jsLogger.error('Error starting streaming session:', error)
    }

    // Connect to LiveKit room
  }

  useEffect(() => {
    const init = async () => {
      jsLogger.debug('HEYGEN: Initializing with Access Token:', heygenKey)

      await createNewSession()
      await startStreamingSession()
    }
    init()

    return () => {
      endSession()
    }
  }, [])

  const endSession = async () => {
    jsLogger.log('End HeyGen session')
    const sessionInfo = sessionInfoRef.current
    if (!sessionInfo) {
      jsLogger.error('No active session')
      return
    }

    jsLogger.log(
      'Stopping streaming session with session_id:',
      get(sessionInfo, 'session_id')
    )
    try {
      const response = await fetchWithRetries(
        `${serverUrl}/v1/streaming.stop`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${heygenKey}`
          },
          body: JSON.stringify({
            session_id: get(sessionInfo, 'session_id')
          })
        }
      )
      jsLogger.log('Streaming stop response:', response)
    } catch (error) {
      jsLogger.error('Error stopping streaming session:', error)
    }

    // Close WebSocket
    if (websocketRef.current) {
      websocketRef.current.close()
    }
    // Disconnect from LiveKit room
    if (roomRef.current) {
      roomRef.current.disconnect()
    }

    jsLogger.log('Session closed')
  }

  const cancelTalking = async () => {
    jsLogger.log('cancelTalking')
    const sessionInfo = sessionInfoRef.current
    if (!sessionInfo) {
      jsLogger.error('cancelTalking: No active session')
      return
    }

    try {
      const response = await fetchWithRetries(
        `${serverUrl}/v1/streaming.interrupt`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${heygenKey}`
          },
          body: JSON.stringify({
            session_id: get(sessionInfo, 'session_id')
          })
        }
      )
      jsLogger.log('interruptResponse', response)
    } catch (error) {
      jsLogger.error('Error in cancelTalking:', error)
    }
  }

  const startTalk = async (text: string) => {
    const sessionInfo = sessionInfoRef.current
    if (!sessionInfo) {
      jsLogger.error('No active session')
      return null
    }

    try {
      startTalkTimestamp.current = Date.now()
      const startTime = Date.now() // Start measuring time
      const response = await fetchWithRetries(
        `${serverUrl}/v1/streaming.task`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${heygenKey}`
          },
          body: JSON.stringify({
            session_id: get(sessionInfo, 'session_id'),
            text: text,
            task_type: 'repeat'
          })
        }
      )
      const latency = Date.now() - startTime // Calculate latency
      const jResponse = await response.json()
      jsLogger.log(`streaming.task response:`, { text, jResponse, latency })
      const taskId = get(jResponse, 'data.task_id', null) as string | null
      jsLogger.log(`streaming.task return task_id`, { taskId })
      return taskId
    } catch (error) {
      jsLogger.error('Error in startTalk:', error)
      return null
    }
  }

  const playVideo = () => {
    jsLogger.log(
      '%cplayVideo, permisssions granted',
      'color: gold;',
      permissionsGrantedRef.current
    )
    // const v = permissionsGranted ? videoRef.current : videoMutedRef.current
    if (!permissionsGrantedRef.current) {
      jsLogger.log('playVideo: permissions are not granted, exit playVideo')
      return null
    }
    const v = videoRef.current
    // const v = videoRef.current
    if (stream && v) {
      if (!videoRecorderRef.current) {
        videoRecorderRef.current = new RVideo(
          stream,
          (v: number) => setDuration(v),
          onNewChunk
        )
      }
      jsLogger.log('pause current video')
      v.pause()

      v.srcObject = stream
      if (!permissionsGrantedRef.current) {
        v.autoplay = false
        v.loop = false
      }
      // v.muted = false
      streamHasBeenSet.current = true
      // v.muted = false
      const playPromise = v.play()
      if (v.paused && has(playPromise, 'next')) {
        v.play()
          .then(() => {
            jsLogger.log('--> Video Play success')
          })
          .catch(e => jsLogger.log('--> Video Play error', e.message))
      } else {
        jsLogger.log('Video is not paused')
      }
      if (permissionsGrantedRef.current) {
        onSessionStarted()
      }
    } else {
      jsLogger.warn('playVideo stream or videoref is not ready')
    }
  }

  // const setVideoElement = async (stream: MediaStream) => {
  //   jsLogger.log('setVideoElement', stream.id)
  //   jsLogger.log(
  //     'setVideoElement: permissions granted',
  //     permissionsGrantedRef.current
  //   )
  //   playVideo()
  // }

  // const onEvent = (e: string) => {}

  // const startSession = async () => {
  //   // setIsLoadingSession(true);
  //   // await updateToken();
  //   const streamingAvatar: StreamingAvatar | null = avatar.current
  //   if (!streamingAvatar) {
  //     jsLogger.error('Avatar API is not initialized')
  //     return
  //   }

  //   const avatarConf: StartAvatarRequest = {
  //     quality: AvatarQuality.High,
  //     avatarName: avatarId
  //   }

  //   if (voiceId) {
  //     avatarConf.voice = {
  //       voiceId,
  //       emotion: VoiceEmotion.FRIENDLY
  //     }
  //   }
  //   try {
  //     const res = await streamingAvatar.createStartAvatar(avatarConf)
  //     jsLogger.log('Session data:', { sessionData: res })
  //     setData(res)
  //     setStream(avatar.current.mediaStream)
  //     setVideoElement(avatar.current.mediaStream)
  //   } catch (error) {
  //     console.error('Error starting avatar session:', error)
  //     jsLogger.error('There was an error starting the session.')
  //     throw error
  //   }
  //   // setIsLoadingSession(false)
  // }

  const stopSession = async () => {
    // const sessionId = sessionIdRef.current
    // if (videoRecorderRef.current) {
    //   videoRecorderRef.current.stop()
    // }
    // if (sessionId) {
    //   _stopSession(sessionId)
    // }
  }

  const onNewChunk = (blob: Blob) => {
    if (videoRecorderRef.current) {
      const mimeType = videoRecorderRef.current.mimeType
      if (mimeType) {
        handleChunk && handleChunk(blob, mimeType, 'avatar')
      }
    }
  }

  return (
    <div
      className='h-full w-full flex flex-col justify-between flex-1 max-w-2xl relative bg-gray-700'
      onClick={handleVideoClick}
    >
      {avatarImageUrl && (
        <img
          src={avatarImageUrl}
          alt='Avatar'
          className='absolute w-full h-full object-cover left-0 top-0 right-0 bottom-0'
        />
      )}
      {
        permissionsGranted ? (
          <>
            <video
              key={'heygen_video'}
              id='heygen_video'
              ref={videoRef}
              className='w-full h-full object-cover absolute left-0 top-0 right-0 bottom-0 bg-gray-700'
              playsInline
              controls={false}
              autoPlay={false}
              muted={false}
              poster={avatarImageUrl}
              onAbort={() => jsLogger.log('Video: onAbort')}
              onError={e => jsLogger.log('Video: onError', get(e, 'message'))}
            />
            {/* <BackgroundRemover key={'heygenVideo'} videoRef={videoRef} /> */}
          </>
        ) : (
          avatarImageUrl && (
            <img
              src={avatarImageUrl}
              alt='Avatar'
              className='absolute w-full h-full object-cover left-0 top-0 right-0 bottom-0'
            />
          )
        )
        //  : (
        // <>
        //   <video
        //     key={'muted_video'}
        //     id='muted_video'
        //     src='avatar_initial.mp4'
        //     poster='https://firebasestorage.googleapis.com/v0/b/tenantflow-ace23.appspot.com/o/avatar_initial_bg.png?alt=media&token=3653cda3-365d-4ddc-9e4b-2160bd949f7b'
        //     ref={videoMutedRef}
        //     className='w-full h-full object-cover absolute left-0 top-0 right-0 bottom-0'
        //     playsInline
        //     autoPlay
        //     loop
        //     muted
        //     onAbort={() => jsLogger.log('Muted Video: onAbort')}
        //     onError={e =>
        //       jsLogger.log('Muted Video: onError ' + get(e, 'message'))
        //     }
        //   />
        //   <BackgroundRemover key={'mutedVideo'} videoRef={videoMutedRef} />
        // </>
        // )}
      }
      {/* {!permissionsGranted && (
        <div className='absolute top-0 left-0 bottom-0 right-0 bg-gray-700 w-full h-full' />
      )} */}
      {isRecording && (
        <div className='absolute top-0 left-0 bottom-0 right-0 bg-black/60 w-full h-full' />
      )}
      {thereWasAnError && (
        <div className='absolute bottom-0 left-0 bg-white'>
          <p className='text-red'>{thereWasAnError}</p>
        </div>
      )}
      {
        // voiceDetectorEnabled && (
        // <VoiceDetector
        //   streamRef={streamRef}
        //   onVoiceDetected={onVoiceDetected}
        // />
        // )
      }
    </div>
  )
}

export default forwardRef(HeyGenConnect)
