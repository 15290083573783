import { TwoFactorT } from 'types/twoFactor'
import * as Sentry from '@sentry/react'
import get from 'lodash/get'
import omitBy from 'lodash/omitBy'
import isNil from 'lodash/isNil'
import PACKAGE from '../../package.json'
import jsLogger from 'js-logger'
import config from 'src/config'

const headers = {
  'Content-Type': 'application/json',
  Accept: 'application/json'
}

const mimeToExtMap = {
  'video/mp4': 'mp4',
  'video/webm': 'webm',
  'video/ogg': 'ogg',
  'video/quicktime': 'mov',
  'video/x-matroska': 'mkv',
  'video/x-msvideo': 'avi'
}

export const sendCode = async (
  email: string,
  agreed: boolean,
  variant: TwoFactorT.VariantT,
  isTest: boolean,
  model: string | null,
  avatar: TwoFactorT.AvatarT
) => {
  try {
    const windowUrl = new URL(window.location.href)
    const noVideo = windowUrl.searchParams.get('nv') !== null
    const lang = windowUrl.searchParams.get('lang')
    const cc = windowUrl.searchParams.get('cc')
    const url = `${config.apiUrl}/two_factor/send`
    const transcriber = windowUrl.searchParams.get('t')
    const params = omitBy(
      {
        email,
        agreed,
        variant,
        isTest,
        version: PACKAGE.version,
        model,
        avatar,
        transcriber,
        noVideo,
        lang,
        recognitionDemo: true,
        cc
      },
      isNil
    )

    const req = await fetch(url, {
      method: 'POST',
      headers,
      body: JSON.stringify(params)
    })
    const res = await req.json()
    return res
  } catch (e) {
    jsLogger.warn('interactionStart error', e)
    // Sentry.captureException(e)
    return null
  }
}

export const getNavigatorLanguage = () => {
  if (navigator.languages && navigator.languages.length) {
    return navigator.languages[0]
  } else {
    return (
      get(navigator, 'userLanguage') ||
      get(navigator, 'language') ||
      get(navigator, 'browserLanguage') ||
      'en'
    )
  }
}

export const conversationStart = async (
  variant: TwoFactorT.VariantT,
  model: string | null,
  avatar: TwoFactorT.AvatarT,
  urlLang: string | null
) => {
  try {
    const url = `${config.apiUrl}/demo2/start`
    const browserLang = getNavigatorLanguage().slice(0, 2).toLowerCase()
    const windowUrl = new URL(window.location.href)
    const transcriber = windowUrl.searchParams.get('t')
    // const pageUrl = new URL(window.location.href)
    const params = {
      //comment out the param to send slack notifictions about local sessions
      slackNotificationsDisabled:
        window.location.hostname === 'localhost' ||
        window.location.hostname === '127.0.0.1',
      variant,
      model,
      version: PACKAGE.version,
      avatar,
      browserLang,
      urlLang,
      customTranscriber: transcriber
    }
    const req = await fetch(url, {
      method: 'POST',
      headers,
      body: JSON.stringify(params)
    })
    jsLogger.log('conversationStart', params)
    const res = await req.json()
    jsLogger.log('conversationStart res', res)
    return res
  } catch (e) {
    jsLogger.error('interactionStart error', e)
    // Sentry.captureException(e)
    return
  }
}

export const checkInterrupt = async (interactionId: string, reply: string) => {
  try {
    const url = `${config.apiUrl}/two_factor/checkInterrupt`
    const params = {
      interactionId,
      reply
    }
    const startTime = Date.now()
    const req = await fetch(url, {
      method: 'POST',
      headers,
      body: JSON.stringify(params)
    })
    const res = await req.json()
    jsLogger.log('checkInterrupt latency', Date.now() - startTime, reply)
    jsLogger.log('checkInterrupt res', res)
    const interrupt = get(res, 'interrupt', false)
    return interrupt
  } catch (e) {
    jsLogger.warn('checkInterrupt error', e)
    // Sentry.captureException(e)
    return false
  }
}

export const generateResponse = async (
  interactionId: string,
  reply: string,
  signal?: AbortSignal
) => {
  try {
    const url = `${config.apiUrl}/demo2/generateResponse`
    const params = {
      interactionId,
      reply
    }
    const startTime = Date.now()
    const req = await fetch(url, {
      method: 'POST',
      headers,
      signal,
      priority: 'high',
      body: JSON.stringify(params)
    })
    const res = await req.json()
    jsLogger.log('generateResponse latency', Date.now() - startTime)
    jsLogger.log('generateResponse res', res)
    return res
  } catch (e) {
    if (get(e, 'name') === 'AbortError') {
      jsLogger.info('generateResponse request has been aborted')
    } else {
      jsLogger.warn('generateResponse error', e)
      // Sentry.captureException(e)
    }
    return null
  }
}

export const applyResponse = async (
  interactionId: string,
  q: TwoFactorT.QuestionT
) => {
  try {
    const url = `${config.apiUrl}/two_factor/applyResponse`
    const params = {
      interactionId,
      q
    }
    const req = await fetch(url, {
      method: 'POST',
      headers,
      priority: 'low',
      body: JSON.stringify(params)
    })
    const res = await req.json()
    jsLogger.log('applyResponse res', res)
    return res
  } catch (e) {
    jsLogger.warn('applyResponse error', e)
    // Sentry.captureException(e)
    return
  }
}

export const submitUserVideo = async (
  interactionId: string,
  videoBlob: Blob,
  mimeType: string,
  duration: number
) => {
  const url = `${
    config.apiUrl
  }/two_factor/${interactionId}/submitUserVideo?mimeType=${encodeURIComponent(
    mimeType
  )}&duration=${duration}`

  const req = await fetch(url, {
    method: 'POST',
    priority: 'low',
    headers: { ...headers, 'Content-Type': 'application/octet-stream' },
    body: videoBlob
  })
  const res = await req.json()
  jsLogger.log('submitUserVideo res', res)
  return res
}

export const submitAvatarVideo = async (
  interactionId: string,
  videoBlob: Blob,
  mimeType: string,
  duration: number
) => {
  const url = `${
    config.apiUrl
  }/two_factor/${interactionId}/submitAvatarVideo?mimeType=${encodeURIComponent(
    mimeType
  )}&duration=${duration}`

  const req = await fetch(url, {
    method: 'POST',
    headers: { ...headers, 'Content-Type': 'application/octet-stream' },
    priority: 'low',
    body: videoBlob
  })
  const res = await req.json()
  jsLogger.log('submitAvatarVideo res', res)
  return res
}

export const getRecapInfo = async (interactionId: string) => {
  const url = `${config.apiUrl}/two_factor/${interactionId}/recap`
  const req = await fetch(url, {
    method: 'GET',
    priority: 'low',
    headers
  })
  if (req.ok) {
    const res = (await req.json()) as TwoFactorT.RecapT
    jsLogger.log('getRecapInfo res', res)
    return res
  } else {
    jsLogger.warn('getRecapInfo error', req.statusText)
    return null
  }
}

export const sendUserScreenshot = async (
  interactionId: string,
  screenshotBlob: Blob,
  mediaType: string
) => {
  try {
    jsLogger.log('sendUserScreenshot', screenshotBlob.size)
    const startTime = Date.now()
    const url = `${config.apiUrl}/demo2/${interactionId}/screenshot?media=${mediaType}`
    const req = await fetch(url, {
      method: 'POST',
      body: screenshotBlob,
      priority: 'high',
      headers: { ...headers, 'Content-Type': 'application/octet-stream' }
    })
    const data = await req.json()
    const latency = Date.now() - startTime
    jsLogger.log('sendUserScreenshot response', { data, latency })
    return data?.success
  } catch (e) {
    jsLogger.error('could not sent screenshot', { error: e })
    return false
  }
}

export const dbSendUserEmail = async (interactionId: string, email: string) => {
  try {
    jsLogger.log('sendUserEmail', { email })
    const startTime = Date.now()
    const url = `${config.apiUrl}/demo2/${interactionId}/submitEmail`
    const req = await fetch(url, {
      method: 'POST',
      body: JSON.stringify({ email }),
      priority: 'low',
      headers
    })
    const data = await req.json()
    const latency = Date.now() - startTime
    jsLogger.log('sendUserEmail response', { data, latency })
    return data?.success
  } catch (e) {
    jsLogger.error('could not send email', { error: e })
    return false
  }
}

export const dbSkipEmail = async (interactionId: string) => {
  try {
    jsLogger.log('dbSkipEmail', { interactionId })
    const startTime = Date.now()
    const url = `${config.apiUrl}/demo2/${interactionId}/skipEmailInput`
    const req = await fetch(url, {
      method: 'POST',
      priority: 'low',
      headers
    })
    const data = await req.json()
    const latency = Date.now() - startTime
    jsLogger.log('dbSkipEmail response', { data, latency })
    return data?.success
  } catch (e) {
    jsLogger.error('dbSkipEmail', { error: e })
    return false
  }
}

export const applyTestScreenshots = async (interactionId: string) => {
  try {
    jsLogger.log('applyTestScreenshots')
    const url = `${config.apiUrl}/two_factor/${interactionId}/testScreenshot`
    const req = await fetch(url, {
      method: 'POST',
      headers
    })
    const data = await req.json()

    jsLogger.log('applyTestScreenshots response', data)
    return data?.success
  } catch (e) {
    jsLogger.error('applyTestScreenshots error', { error: e })
    return false
  }
}

export const interactionSetLang = async (
  interactionId: string,
  lang: string
) => {
  try {
    jsLogger.log('interactionSetLang', { lang })
    const url = `${config.apiUrl}/two_factor/${interactionId}/setLang`
    const req = await fetch(url, {
      method: 'POST',
      headers,
      priority: 'low',
      body: JSON.stringify({
        lang
      })
    })
    const data = await req.json()
    jsLogger.log('interactionSetLang response', data)
    return null
  } catch (e) {
    jsLogger.error('interactionSetLang error', { error: e })
    return null
  }
}

export const generateId = (): string => {
  const currentDate = new Date()
  const year = currentDate.getFullYear()
  const month = (currentDate.getMonth() + 1).toString().padStart(2, '0')
  const day = currentDate.getDate().toString().padStart(2, '0')
  const hours = currentDate.getHours().toString().padStart(2, '0')
  const minutes = currentDate.getMinutes().toString().padStart(2, '0')
  const seconds = currentDate.getSeconds().toString().padStart(2, '0')
  const milliseconds = currentDate.getMilliseconds().toString().padStart(3, '0')
  const id = `${year}${month}${day}${hours}${minutes}${seconds}${milliseconds}`
  return id
}

export const uploadBlobToStorage = async (
  blob: Blob,
  storagePath: string
): Promise<void> => {
  const extension = get(mimeToExtMap, blob.type.split(';')[0]) || 'webm'
  const storageEndpoint = `https://firebasestorage.googleapis.com/v0/b/${
    config.firebaseStorageId
  }/o/${encodeURIComponent(storagePath)}.${extension}`
  try {
    jsLogger.log('Blob details:', {
      type: blob.type,
      size: blob.size,
      extension
    })
    const responseBlob = await fetch(storageEndpoint, {
      method: 'POST',
      headers: {
        'Content-Type': blob.type
      },
      body: blob
    })

    if (!responseBlob.ok) {
      throw new Error(
        `Failed to upload Blob to storage: ${responseBlob.statusText}`
      )
    }

    jsLogger.log('Blob uploaded successfully')
  } catch (error) {
    jsLogger.error('Error uploading Blob to storage:', error)
  }
}

export const sendError = async (
  event: Sentry.Event,
  hint: Sentry.EventHint
) => {
  const url = `${config.apiUrl}/two_factor/error`
  const req = await fetch(url, {
    method: 'POST',
    priority: 'low',
    body: JSON.stringify({ event, hint }),
    headers
  })
  const data = await req.json()

  jsLogger.log('sendError response', data)
  return data
}

export const setChunksCompleted = async (
  interactionId: string,
  duration: number
) => {
  try {
    const url = `${config.apiUrl}/two_factor/${interactionId}/chunks-completed`
    await fetch(url, {
      method: 'POST',
      headers,
      body: JSON.stringify({ duration })
    })
  } catch (e) {
    jsLogger.error('setChunksCompleted error', e)
  }
}

export const flushLogs = async (interactionId: string) => {}

export const checkIsWebMSupported = () => {
  var video = document.createElement('video')

  return video.canPlayType('video/webm') !== ''
}

export const validateEmail = async (email: string) => {
  const source = 'code'
  const url = 'https://p-gpdh46ebpa-uc.a.run.app/validateEmail'
  const req = await fetch(url, {
    method: 'POST',
    body: JSON.stringify({ email, source })
  })
  const data = await req.json()
  jsLogger.log('validateEmail response', data)
  return data
}

export const sendAvatarLatency = async (
  interactionId: string,
  phraseId: string,
  latency: number
) => {
  try {
    const url = `${config.backendUrl}/two_factor/avatar_latency`
    const params = {
      interactionId,
      phraseId,
      priority: 'low',
      latency
    }
    const req = await fetch(url, {
      method: 'POST',
      headers,
      body: JSON.stringify(params)
    })
    const res = await req.json()
    jsLogger.log('sendAvatarLatency res', res)
    return res
  } catch (e) {
    jsLogger.warn('sendAvatarLatency error', e)
    // Sentry.captureException(e)
    return
  }
}
