import { FC, useEffect, useState } from 'react'
import map from 'lodash/map'
import startCase from 'lodash/startCase'
import sortBy from 'lodash/sortBy'
import toString from 'lodash/toString'
import forEach from 'lodash/forEach'

interface Props {
  data: Record<string, any>
}

type Elt = {
  title: string
  description: string
}

const BackgroundData: FC<Props> = ({ data }) => {
  const [elements, setElements] = useState<[Elt[], Elt[]]>([[], []])

  useEffect(() => {
    const elts = map(data, (v, k) => {
      const elt: Elt = {
        title: startCase(k),
        description: toString(v)
      }
      return elt
    })
    const sortedElements = sortBy(elts, elt => elt.description.length)
    const col1: Elt[] = []
    const col2: Elt[] = []
    forEach(sortedElements, (elt, i) => {
      if (i % 2 === 0) {
        col2.push(elt)
      } else {
        col1.push(elt)
      }
    })
    setElements([col1, col2])
  }, [data])

  const renderColumn = (elts: Elt[]) => {
    return (
      <div className='flex flex-1 flex-col overflow-hidden gap-1'>
        {map(elts, elt => {
          return (
            <div className='flex flex-col w-full' key={elt.title}>
              <span className='text-sm font-bold text-gray-700'>
                {elt.title}
              </span>
              <span className='text-xs text-gray-700 break-words'>
                {elt.description}
              </span>
            </div>
          )
        })}
      </div>
    )
  }

  return (
    <div className='flex w-full flex-col bg-gray-50 drop-shadow-md p-4 gap-2 mt-4'>
      <p className='font-medium text-md text-black'>Background data</p>
      <div className='w-full flex flex-row flex-grow-0 max-w-full overflow-hidden gap-4'>
        {renderColumn(elements[1])}
        {renderColumn(elements[0])}
      </div>
    </div>
  )
}

export default BackgroundData
